import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { FiGlobe, FiHeadphones, FiDollarSign } from 'react-icons/fi';

import './Menu.css';

import Modal from './Modal';

import I18N from '../utils/I18N';

import Logo from '../assets/logo.png';

function Menu() {
  const cookies = new Cookies();
  const locale = cookies.get('locale') || 'pt_BR';

  const [showModal, setShowModal] = useState(false);

  function openModal() {
    document.body.style.overflow = "hidden";
    setShowModal(true);
  }

  function closeModal() {
    document.body.style.overflow = "auto";
    setShowModal(false);
  }

  function openSupport() {
    const url = I18N.TRANSLATE.SOCIAL_MEDIA.SUPPORT[locale];

    const win = window.open(url, '_blank');
    win.focus();
  }

  function openFinancial() {
    const win = window.open('https://wa.me/554833644120', '_blank');
    win.focus();
  }

  return (
    <>
      <Modal show={showModal} onClose={closeModal} />
      <header className="header">
        <img className="logo" src={Logo} />
        <div className="button-container">
          <div className="button" onClick={openFinancial}>
            <FiDollarSign size={25} color="#23A455" />
            <p>{I18N.TRANSLATE.MENU.FINANCIAL[locale]}</p>
          </div>
          <div className="button" onClick={openSupport}>
            <FiHeadphones size={25} color="#23A455" />
            <p>{I18N.TRANSLATE.MENU.SUPPORT[locale]}</p>
          </div>
          <div className="button" onClick={openModal}>
            <FiGlobe size={25} color="#23A455" />
            <p>{I18N.TRANSLATE.MENU.LANGUAGE[locale]}</p>
          </div>
        </div>
      </header>
    </>
  );
}

export default Menu;
