import React from 'react'
import Cookies from 'universal-cookie';

import './Main.css';

import Menu from './Menu';
import Footer from './Footer';
import ListItem from './ListItem';
import Separator from './Separator';
import Message from './Message';

import I18N from '../utils/I18N';

function Main() {
  const cookies = new Cookies();
  const locale = cookies.get('locale') || 'pt_BR';

  function openOZmap() {
    fetch("https://" + window.location.hostname + ":9994").then(function () {
      const win = window.open("https://" + window.location.hostname + ":9994", '_blank');
      win.focus();
    }).catch(function () {
      const win = window.open("http://" + window.location.hostname + ":9090", '_blank');
      win.focus();
    })
  }

  function openOZloc() {
    fetch("https://" + window.location.hostname + ":9443").then(function () {
      const win = window.open("https://" + window.location.hostname + ":9443", '_blank');
      win.focus();
    }).catch(function () {
      const win = window.open("http://" + window.location.hostname + ":8081", '_blank');
      win.focus();
    })
  }

  function openDashboard() {
    fetch("https://" + window.location.hostname + ":9994").then(function () {
      const win = window.open("https://" + window.location.hostname + ":9994/?menuItem=tabDashboard", '_blank');
      win.focus();
    }).catch(function () {
      const win = window.open("http://" + window.location.hostname + ":9090/?menuItem=tabDashboard", '_blank');
      win.focus();
    })
  }

  function openOZmob() {
    const win = window.open('http://ozmap.com.br/ozmob', '_blank');
    win.focus();
  }

  function openOZconverter() {
    const win = window.open('http://ozconverter.ozmap.com.br/', '_blank');
    win.focus();
  }

  function openOZsurvey() {
    const win = window.open('http://ozmap.com.br/ozsurvey/', '_blank');
    win.focus();
  }

  function openFindMe() {
    const win = window.open('https://ajuda.ozmap.com.br/support/solutions/articles/44001694749-pedindo-a-localizac%C3%A3o-do-cliente-findme', '_blank');
    win.focus();
  }

  function openNews() {
    const win = window.open('http://ozmap.com.br/release-notes-geral/', '_blank');
    win.focus();
  }

  return (
    <div>
      <Message onClick={openNews} text={I18N.TRANSLATE.NEWS[locale]} />
      <Menu />
      <div className="main-container">
        <div className="sub-container">
          <Separator icon="FiBox" title={I18N.TRANSLATE.MODULES.TITLE[locale]} />
          <div className="items">
            <ListItem onClick={openOZmap} title="OZmap" text={I18N.TRANSLATE.MODULES.OZMAP_DESCRIPTION[locale]} />
            <ListItem onClick={openOZmob} title="OZmob" text={I18N.TRANSLATE.MODULES.OZMOB_DESCRIPTION[locale]} />
            <ListItem onClick={openOZloc} title="OZloc" text={I18N.TRANSLATE.MODULES.OZLOC_DESCRIPTION[locale]} />
            <ListItem onClick={openDashboard} title="Dashboard" text={I18N.TRANSLATE.MODULES.DASHBOARD_DESCRIPTION[locale]} />
          </div>

          <Separator icon="FiTool" title={I18N.TRANSLATE.TOOLS.TITLE[locale]} />
          <div className="items">
            <ListItem onClick={openOZsurvey} title="OZsurvey" text={I18N.TRANSLATE.TOOLS.OZSURVEY_DESCRIPTION[locale]} />
            <ListItem onClick={openFindMe} title="Findme" text={I18N.TRANSLATE.TOOLS.FINDME_DESCRIPTION[locale]} />
            <ListItem onClick={openOZconverter} title="OZconverter" text={I18N.TRANSLATE.TOOLS.OZCONVERTER_DESCRIPTION[locale]} />
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
