import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';

import './Modal.css';

function Modal({ show, onClose }) {
    const cookies = new Cookies();

    const [language, setLanguage] = useState('');

    useEffect(() => {
        const initialLanguage = cookies.get('locale') || 'pt_BR';
        setLanguage(initialLanguage);
    },[]);

    function handleSelection() {
        cookies.set('locale', language);
        document.location.reload(true);
        onClose();
    }

    if (!show) {
        return null;
    }

    return (
        <div className="modalContainer">
            <div className="modal">
                <select name="languages" value={language} onChange={e => setLanguage(e.target.value)}>
                    <option value="pt_BR">Português</option>
                    <option value="es">Español</option>
                    <option value="en">English</option>
                </select>
                <button onClick={handleSelection}>Selecionar</button>
            </div>
        </div>
    );
}

export default Modal;
