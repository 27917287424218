import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

import './ListItem.css';

function ListItem(params) {
    return (
        <a>
            <li onClick={params.onClick}>
                <div className="item-header">
                    <h3>{params.title}</h3>
                    <FiArrowRight size={16} color="#202024" />
                </div>
                <div className="item-content">
                    <p>{params.text}</p>
                </div>
            </li>
        </a>
    );
}

export default ListItem;
