import React from 'react';
import * as ReactIcons from 'react-icons/fi';

import './Separator.css';

function Separator(params) {

    const Icon = ReactIcons[params.icon];

    return (
        <div className="separator">
            {Icon && <Icon size={25} color="#F2F2F2"/>}
            <h3>{params.title}</h3>
        </div>
    );
}

export default Separator;
