import React from 'react'
import Cookies from 'universal-cookie';
import { FiFacebook, FiInstagram, FiLinkedin, FiYoutube } from 'react-icons/fi';

import './Footer.css';


import I18N from '../utils/I18N';

function Footer() {
    const cookies = new Cookies();
    const locale = cookies.get('locale') || 'pt_BR';

    function openFacebook() {
        const url = I18N.TRANSLATE.SOCIAL_MEDIA.FACEBOOK[locale];

        const win = window.open(url, '_blank')
        win.focus();
    }


    function openInstagram() {
        const url = I18N.TRANSLATE.SOCIAL_MEDIA.INSTAGRAM[locale];

        const win = window.open(url, '_blank')
        win.focus();
    }


    function openLinkedIn() {
        const url = I18N.TRANSLATE.SOCIAL_MEDIA.LINKEDIN[locale];

        const win = window.open(url, '_blank')
        win.focus();
    }

    function openYoutube() {
        const url = I18N.TRANSLATE.SOCIAL_MEDIA.YOUTUBE[locale];

        const win = window.open(url, '_blank')
        win.focus();
    }

    return (
        <footer className="footer">
            <div className="icon" onClick={openFacebook}>
                <FiFacebook size={25} color="#F2F2F2" />
            </div>
            <div className="icon">
                <FiInstagram size={25} color="#F2F2F2" onClick={openInstagram} />
            </div>
            <div className="icon">
                <FiLinkedin size={25} color="#F2F2F2" onClick={openLinkedIn} />
            </div>
            <div className="icon">
                <FiYoutube size={25} color="#F2F2F2" onClick={openYoutube} />
            </div>
        </footer>
    );
}

export default Footer;
