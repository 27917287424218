import React from 'react'
import { FiArrowRight } from 'react-icons/fi';

import './Message.css';

function Message({ text, onClick }) {

    return (
        <div className="message" onClick={onClick}>
            <p>{text}</p>
            <FiArrowRight size={16} color="#F2F2F2" />
        </div>
    );
}

export default Message;
