export default class I18N {
    static DEFAULT_LOCALE = 'pt_BR';

    static TRANSLATE = {
        SOCIAL_MEDIA: {
            YOUTUBE: {
                pt_BR: "https://www.youtube.com/channel/UCKfDSVnwO64IUvzZMPZFtSg",
                en: "https://www.youtube.com/channel/UCkJofaHHF_wXOrrrZZAgnhA",
                es: "https://www.youtube.com/channel/UCCGXf4b4-IYmT3qJJqHtJ4g"
            },
            LINKEDIN: {
                pt_BR: "https://www.linkedin.com/company/ozmap/mycompany/",
                es: "https://www.linkedin.com/showcase/ozmapes/",
                en: "https://www.linkedin.com/showcase/ozmapen/about/"
            },
            INSTAGRAM: {
                pt_BR: "https://www.instagram.com/devozmap/",
                es: "https://www.instagram.com/ozmapes/",
                en: "https://www.instagram.com/ozmapen/"
            },
            FACEBOOK: {
                pt_BR: "https://www.facebook.com/devOZmap/",
                es: "https://www.facebook.com/ozmapes/",
                en: "https://www.facebook.com/OZmapEN/"
            },
            TELEGRAM: {
                pt_BR: "https://t.me/devozmap",
                es: "https://t.me/devozmap",
                en: "https://t.me/devozmap"
            },
            WHASTAPP: {
                pt_BR: "https://api.whatsapp.com/send?phone=5548991141980",
                es: "https://api.whatsapp.com/send?phone=5548991141980",
                en: "https://api.whatsapp.com/send?phone=5548991141980"
            },
            SUPPORT:{
                pt_BR: "https://ajuda.ozmap.com.br/support/solutions",
                es: "https://ajuda.ozmap.com.br/es-LA/support/solutions",
                en: "https://ozmap-en.freshdesk.com/support/home" 
            }
        },
        MENU: {
            FINANCIAL:{
                pt_BR: "Financeiro",
                es: "Financiero",
                en: "Financial"  
            },
            MANAGEMENT: {
                pt_BR: "Administrativo",
                es: "Administrativo",
                en: "Management"
            },
            SUPPORT: {
                pt_BR: "Suporte",
                es: "Soporte",
                en: "Support"
            },
            LANGUAGE: {
                pt_BR: "Idioma",
                es: "Idioma",
                en: "Language"
            }
        },
        MODULES: {
            TITLE: {
                pt_BR: "Módulos",
                es: "Módulos",
                en: "Modules"
            },
            OZMAP_DESCRIPTION:{
                pt_BR: "Software de mapeamento de redes",
                es: "Software de mapeo de red",
                en: "Network mapping software" 
            },
            OZMOB_DESCRIPTION:{
                pt_BR: "App mobile integrado ao OZmap",
                es: "Aplicación móvil integrada con OZmap",
                en: "Mobile app integrated with OZmap" 
            },
            OZLOC_DESCRIPTION:{
                pt_BR: "Módulo de viabilidade integrado ao OZmap",
                es: "Módulo de viabilidad integrado con OZmap",
                en: "Viability module integrated with OZmap" 
            },
            DASHBOARD_DESCRIPTION:{
                pt_BR: "Gerenciamento de dados do OZmap",
                es: "Gestión de datos OZmap",
                en: "OZmap data management" 
            },
        },
        TOOLS:{
            TITLE: {
                pt_BR: "Ferramentas",
                es: "Herramientas",
                en: "Tools"
            }, 
            OZSURVEY_DESCRIPTION:{
                pt_BR: "App para marcação de postes, caixas, clientes e o que mais você precisar marcar em um mapa",
                es: "App para marcar puestos, buzones, clientes y todo lo que necesites marcar en un mapa",
                en: "App for marking posts, boxes, customers and whatever else you need to mark on a map" 
            },
            FINDME_DESCRIPTION:{
                pt_BR: "Ferramenta para localizar clientes no ozloc",
                es: "Herramienta para encontrar clientes en ozloc",
                en: "Tool to find ozloc clients" 
            },
            OZCONVERTER_DESCRIPTION:{
                pt_BR: "Ferramenta que transforma arquivos KMZs, KMLs e GPXs em XLS e CSV",
                es: "Herramienta que transforma archivos KMZs, KMLs y GPXs en XLS y CSV",
                en: "Tool that transforms KMZs, KMLs and GPXs files into XLS and CSV" 
            },
        },
        NEWS:{
            pt_BR: "Novidades",
            es: "Noticias",
            en: "News"   
        }
    };
}
